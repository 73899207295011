import type { Send, State } from "@daangn/sprout-hooks-radio-group";
import React from "react";

import type { SeedChipRadioGroupProps } from "./types";

export const RadioContext = React.createContext<{
  state: readonly [State, Send];
  size: SeedChipRadioGroupProps["size"];
} | null>(null);

export const useRadioContext = () => {
  const context = React.useContext(RadioContext);
  if (!context) {
    throw new Error(`Radio cannot be rendered outside the RadioGroup`);
  }
  return context;
};
