import * as button from "@daangn/sprout-machines-button";
import { filterDataProps } from "@daangn/sprout-react-utils";
import { mergeProps, normalizeProps, useMachine } from "@zag-js/react";
import { useId } from "react";

import type { ButtonElementType, ButtonProps } from "./types";

export function useButton<T extends ButtonElementType>(
  props: ButtonProps<T>,
): button.Api {
  const autoId = useId();

  const {
    id = autoId,
    isDisabled: disabled = false,
    elementType = "button" as const,
    type = "button",
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "aria-details": ariaDetails,
    download,
    href,
    rel,
    target,
    onClick,
  } = props;

  const dataProps = filterDataProps(props);

  const context = {
    disabled,
    elementType,
    type,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "aria-details": ariaDetails,
    download,
    href,
    rel,
    target,
  };

  const [state, send] = useMachine(
    button.machine({
      id,
      ...context,
    }),
    {
      context,
    },
  );

  const buttonApi: button.Api = button.connect(state, send, normalizeProps);

  return {
    ...buttonApi,
    buttonProps: mergeProps(buttonApi.buttonProps, dataProps, { onClick }),
  };
}
