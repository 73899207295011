import type { CSSProperties, HTMLAttributes } from "react";

import type { StyleHandlers } from "./handler";
import { basePropHandlers } from "./handler";
import type { BaseStyleProps, ContainerStyleProps } from "./types";

const borderStyleProps = {
  borderWidth: "borderStyle",
  borderLeftWidth: "borderLeftStyle",
  borderRightWidth: "borderRightStyle",
  borderTopWidth: "borderTopStyle",
  borderBottomWidth: "borderBottomStyle",
};

export function convertStyleProps(
  props: ContainerStyleProps,
  handlers: StyleHandlers,
) {
  const style: Record<string, string | undefined> = {}; // CSSProperties
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { UNSAFE_className, UNSAFE_style, ...otherProps } = props;
  const styleKeys = Object.keys(otherProps) as Exclude<
    keyof ContainerStyleProps,
    "UNSAFE_className" | "UNSAFE_style"
  >[];
  for (const key of styleKeys) {
    const styleProp = handlers[key];
    if (!styleProp || otherProps[key] == null) {
      continue;
    }

    const [name, convert] = styleProp;
    const prop = otherProps[key];
    const value = convert(prop);
    for (const k of name) {
      style[k] = value;
    }
  }

  for (const prop in borderStyleProps) {
    const borderStyleProp =
      borderStyleProps[prop as keyof typeof borderStyleProps];
    if (style[prop]) {
      style[borderStyleProp] = "solid";
      style.boxSizing = "border-box";
    }
  }

  return style as CSSProperties;
}

export function useStyleProps<T extends BaseStyleProps>(
  props: T,
  handlers: StyleHandlers = basePropHandlers,
) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { UNSAFE_className, UNSAFE_style, ...otherProps } = props;
  const style = { ...UNSAFE_style, ...convertStyleProps(props, handlers) };

  // @ts-ignore
  if (otherProps.className) {
    console.warn("use UNSAFE_className instead of className");
  }

  // @ts-ignore
  if (otherProps.style) {
    console.warn("use UNSAFE_style instead of style");
  }

  const styleProps: Pick<
    HTMLAttributes<HTMLElement>,
    "style" | "className" | "hidden"
  > = {
    style,
    className: UNSAFE_className,
  };

  if (props.isHidden) {
    styleProps.hidden = true;
  }

  return {
    styleProps,
  };
}
