import "@daangn/sprout-css/switch/index.css";

import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import type { SwitchProps } from "@daangn/sprout-hooks-switch";
import { useSwitch } from "@daangn/sprout-hooks-switch";
import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import React from "react";

export interface SeedSwitchProps extends SwitchProps, BaseStyleProps {}

const Switch = (props: SeedSwitchProps, ref: Ref<HTMLLabelElement>) => {
  const { ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);
  const { rootProps, controlProps, hiddenInputProps } = useSwitch(otherProps);
  const { focusVisibleProps } = useFocusVisible();

  return (
    <label
      ref={ref}
      {...styleProps}
      {...focusVisibleProps}
      {...rootProps}
      className={composeClassName("seed-switch", styleProps.className)}
    >
      <div {...controlProps}></div>
      <input {...hiddenInputProps}></input>
    </label>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Switch = React.forwardRef(Switch);
export { _Switch as Switch };
