import 'src/basicUIPlugin.css.ts.vanilla.css?source=#H4sIAAAAAAAAE+2U30rDMBTG7/cUAW8UTGmrdVAfRtLktD1bmxPzZw7FdzcbG95s1LaCCKW9SML55XyQ73zJS/b6pDb5JmUfK8Y4d17Ibd3RGzddaFDzSjiUPGBcyG1jKWjFJXVkS3ZT1/XzAKWwv0DaphK36T07/UlW3A1d5K3QDj2S5ipYcViULHVDmKTeBA9qKi+MiRvLK7IK7DX96XpQ/6WLfkmUw3coWWb2P+VawKb1JXtICgv9OGqu5j4eTuuPMrY8Wy/P4vc4Hp0r38PeTxTxjc5+90mjeI2eq4Z2YA8FJWtRKdBjnOBEDVxYEBy1g2guMrH1sJMr8p567lqI0GkMrFAYXByEo6c+V8k527Il2/55tqVJMT7d8mT9t/E2UsCSb0u+Tcq34phvhlD7WAY70D6WadIQy74Agy2ZNuIJAAA=';
import 'src/styles/f.css.ts.vanilla.css?source=LmRodGh4cTAgewogIHdpZHRoOiAxMDAlOwp9Ci5kaHRoeHExIHsKICBoZWlnaHQ6IDEwMCU7Cn0KLmRodGh4cTIgewogIHRvcDogMDsKfQouZGh0aHhxMyB7CiAgbGVmdDogMDsKfQouZGh0aHhxNCB7CiAgcG9zaXRpb246IGFic29sdXRlOwp9Ci5kaHRoeHE1IHsKICBwb3NpdGlvbjogcmVsYXRpdmU7Cn0KLmRodGh4cTcgewogIGRpc3BsYXk6IGZsZXg7Cn0KLmRodGh4cTggewogIGZsZXg6IDE7Cn0KLmRodGh4cTkgewogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLmRodGh4cWEgewogIGFsaWduLWl0ZW1zOiBmbGV4LWVuZDsKfQouZGh0aHhxYiB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLmRodGh4cWMgewogIG92ZXJmbG93OiBoaWRkZW47Cn0KLmRodGh4cWQgewogIHdoaXRlLXNwYWNlOiBub3dyYXA7Cn0KLmRodGh4cWUgewogIHRleHQtYWxpZ246IGNlbnRlcjsKfQouZGh0aHhxZiB7CiAgY3Vyc29yOiBwb2ludGVyOwogIC13ZWJraXQtdGFwLWhpZ2hsaWdodC1jb2xvcjogdHJhbnNwYXJlbnQ7Cn0KLmRodGh4cWcgewogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7Cn0KLmRodGh4cWggewogIGJveC1zaXppbmc6IGNvbnRlbnQtYm94Owp9Ci5kaHRoeHFpIHsKICBhcHBlYXJhbmNlOiBub25lOwogIGJvcmRlcjogMDsKICBwYWRkaW5nOiAwOwogIGJhY2tncm91bmQ6IG5vbmU7Cn0=';
import 'src/components/AppScreen.css.ts.vanilla.css?source=#H4sIAAAAAAAAE9WU0bKaMBCG732K3HRGpsQJes5pizd9k05IIgZDQkPQ03bOuzcEUAIK6vSmjhfisl/+3f2zqx8RFbuXzcsb+LMAIMHkkGpVSQqJEkrH4Ij1EsLS2MBOqBMsRJVyCRNccgIrDinP4TAr2C4+FquO/OVJ8jT1q6MajWXJDVcybn7vlM5bcvcmCkKgCky4+TWKeEhyQdaYliiwYRu6jBD6FAJkv34Sd0ktPwZoa59+Qy4pe48Hx0VBHTxxISDZY5myuMtriT/faLbOEBjA94ynexODCGmWb71exkBwybCGqcaUM2mWT80rBDpN8BI1BdY1uiLtUc3nLOhbT1s4DuNeuIt67YmuY5NpLJnDIm8kWRM8Mu06YOMl0UqIunXwxJIDN/AcbUJcpnbcqiL7ifmtx/M7m8UXEMfdOQ0+wdpporwsBLaCpJLMG3oEBvLvs+G5V65bI1RvXFk4825vdtnl1QkpyL8OI/NmV27GbZQz91RZ6IGy0D1l9U15b5EN6PBPdk8ICkypdR40qpjdhrgo7IOGzTKAl8MhrTSufzh39pDzG9ZnBrcMKWZc0HryphHyqfzX62tVupwTp2ZvB7Rav7ar78bV3PjZqjnxqR5c9i3BgrgLB+CjGCfne1kVhdKmBEtvLERJC7K7usQ7BrFm2BZVMlNHg8BJH1htMFcn7EFF4DPI8fvdWbn985q8cEa9a+DHRPFMHv/Pum8K70r+CyWqxT1LCQAA';
import 'src/components/AppBar.css.ts.vanilla.css?source=#H4sIAAAAAAAAE+1YyZLbIBC9z1dwSZWdMirkZRb5lkNuOeUDppCEbMYYFIG3SeXfA2hDkuWtXDlMxuWDBd1N9+vXTcveq39YTfB0hsDvBwDWlMMloYulCsAWZwMIpcLRKmFiB1O2WejtEEsawQ2FOE31QwZrneH84c+DV1r0rUWxJZlRv9xeqdG0NrbWQq28yMSGxzASTGSXW21rausAvEPKY7Ivrbz6MUumk+nUbu4oYzBaYr4gAVAZ5jIR2XoERIojqg5GxK5SRQUPOq7d7BmsrcJ4k2HzYzgCodhDucSx2F1uWWQxyU5YLRD+9Ri/jd980AI7FWVsOJSCbRTpyueAhW3VCq4COYYVmcQDH6EvI4D0d/gR4BvVcQIkG+CgNiIFawKA5v34aGR8lJH1RwSoWWJoWBVSZ8cAqYMvP01Mc6EXB97RGVnsyNaijZz4p3NSENbpRpO8G1WQBYBySRRA6R5EmEUD6IOv1+Io6TsZDgG6Cf/eWp6eKkhLOLRdfhLOIZFDI/8o5aajs9IO6aau8OlcuEQ71WQ/c3pRTo9149nNld9pLGHb6n986+UwPFoYygFyjfeDa4ZIiRMCcUYwtM0UKpHqwyLBtTpXg2PbFtD7nkf4tu8oJ9KnRqSXnlqNyU0W5MtXWjk10OU+PudTHI5jyhd68ADIm+nhoikTkHWqy8dIxlSmDOui4IKThtSL3b9y4qY6c86s7QZcFu0EobUcgeuoX9vtgcCM7rFaBmDsjfOA60yNvacOBi8BjhTdktac5o373Eb399lxJ7J+FCE029rMRndlHhTZq5483I941wJSO3X+xcTp3g1sTF81Eb1tpKLJQZvjinCd5ISRvfEhU/O6ACAjid7zC0IkWthOXHrJ85+fSqIwykn1Cux7s0p2V6yFgsXzcvij77a0ip6pl/qmsKj3jcqpO+O3WcCMLjikiqxlACIdEsmOxllvWTitmrtq/U7wmjJt3EDPCJQHqcwLxjcd6OoHjn7a5+9ashPpI0JtpEqQLJSzHP5jt94A6s1Gc76lRYr0310idzzs3A3SYXXsNuqCp8ibdDtV3qIa6NdUrXrd6YToGGFRQKVCq/GfaD7HyU2a10fIRLRq+J3k45Ehaf1/EGGMppLKFscoX5KMdtlYrTt2F303nAkzM2nLscIbJZqKZ669Top6TjI/tMpfW56iAkYTAAA=';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var appBar = _7a468({defaultClassName:'_1yk3a452 dhthxq4 dhthxq0 dhthxqh _1dlf4347 _1yk3a451',variantClassNames:{border:{true:'_1yk3a453'},modalPresentationStyle:{fullScreen:'_1yk3a454'},activityEnterStyle:{slideInLeft:'_1yk3a455'}},defaultVariants:{},compoundVariants:[]});
export var backButton = '_1yk3a459 dhthxq9 dhthxq7 dhthxqb dhthxqf dhthxqi';
export var center = 'dhthxq9 dhthxq7 dhthxq8 _1yk3a450';
export var centerMain = _7a468({defaultClassName:'_1yk3a45c',variantClassNames:{hasLeft:{true:'_1yk3a45d'}},defaultVariants:{},compoundVariants:[]});
export var centerMainEdge = '_1yk3a45e dhthxqi dhthxq4 dhthxq2 dhthxqf';
export var centerText = '_1yk3a45f dhthxqc dhthxqd dhthxq0';
export var closeButton = '_1yk3a459 dhthxq9 dhthxq7 dhthxqb dhthxqf dhthxqi';
export var container = '_1yk3a457 dhthxqa dhthxq7 _1yk3a451';
export var left = '_1yk3a458 dhthxq9 dhthxq7 dhthxq1 _1yk3a450';
export var right = '_1yk3a45g dhthxq9 dhthxq7 dhthxq1 dhthxq5 _1yk3a450';
export var safeArea = '_1yk3a456';