import 'src/basicUIPlugin.css.ts.vanilla.css?source=#H4sIAAAAAAAAE+2U30rDMBTG7/cUAW8UTGmrdVAfRtLktD1bmxPzZw7FdzcbG95s1LaCCKW9SML55XyQ73zJS/b6pDb5JmUfK8Y4d17Ibd3RGzddaFDzSjiUPGBcyG1jKWjFJXVkS3ZT1/XzAKWwv0DaphK36T07/UlW3A1d5K3QDj2S5ipYcViULHVDmKTeBA9qKi+MiRvLK7IK7DX96XpQ/6WLfkmUw3coWWb2P+VawKb1JXtICgv9OGqu5j4eTuuPMrY8Wy/P4vc4Hp0r38PeTxTxjc5+90mjeI2eq4Z2YA8FJWtRKdBjnOBEDVxYEBy1g2guMrH1sJMr8p567lqI0GkMrFAYXByEo6c+V8k527Il2/55tqVJMT7d8mT9t/E2UsCSb0u+Tcq34phvhlD7WAY70D6WadIQy74Agy2ZNuIJAAA=';
import 'src/styles/f.css.ts.vanilla.css?source=LmRodGh4cTAgewogIHdpZHRoOiAxMDAlOwp9Ci5kaHRoeHExIHsKICBoZWlnaHQ6IDEwMCU7Cn0KLmRodGh4cTIgewogIHRvcDogMDsKfQouZGh0aHhxMyB7CiAgbGVmdDogMDsKfQouZGh0aHhxNCB7CiAgcG9zaXRpb246IGFic29sdXRlOwp9Ci5kaHRoeHE1IHsKICBwb3NpdGlvbjogcmVsYXRpdmU7Cn0KLmRodGh4cTcgewogIGRpc3BsYXk6IGZsZXg7Cn0KLmRodGh4cTggewogIGZsZXg6IDE7Cn0KLmRodGh4cTkgewogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLmRodGh4cWEgewogIGFsaWduLWl0ZW1zOiBmbGV4LWVuZDsKfQouZGh0aHhxYiB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLmRodGh4cWMgewogIG92ZXJmbG93OiBoaWRkZW47Cn0KLmRodGh4cWQgewogIHdoaXRlLXNwYWNlOiBub3dyYXA7Cn0KLmRodGh4cWUgewogIHRleHQtYWxpZ246IGNlbnRlcjsKfQouZGh0aHhxZiB7CiAgY3Vyc29yOiBwb2ludGVyOwogIC13ZWJraXQtdGFwLWhpZ2hsaWdodC1jb2xvcjogdHJhbnNwYXJlbnQ7Cn0KLmRodGh4cWcgewogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7Cn0KLmRodGh4cWggewogIGJveC1zaXppbmc6IGNvbnRlbnQtYm94Owp9Ci5kaHRoeHFpIHsKICBhcHBlYXJhbmNlOiBub25lOwogIGJvcmRlcjogMDsKICBwYWRkaW5nOiAwOwogIGJhY2tncm91bmQ6IG5vbmU7Cn0=';
import 'src/components/AppScreen.css.ts.vanilla.css?source=#H4sIAAAAAAAAE9WU0bKaMBCG732K3HRGpsQJes5pizd9k05IIgZDQkPQ03bOuzcEUAIK6vSmjhfisl/+3f2zqx8RFbuXzcsb+LMAIMHkkGpVSQqJEkrH4Ij1EsLS2MBOqBMsRJVyCRNccgIrDinP4TAr2C4+FquO/OVJ8jT1q6MajWXJDVcybn7vlM5bcvcmCkKgCky4+TWKeEhyQdaYliiwYRu6jBD6FAJkv34Sd0ktPwZoa59+Qy4pe48Hx0VBHTxxISDZY5myuMtriT/faLbOEBjA94ynexODCGmWb71exkBwybCGqcaUM2mWT80rBDpN8BI1BdY1uiLtUc3nLOhbT1s4DuNeuIt67YmuY5NpLJnDIm8kWRM8Mu06YOMl0UqIunXwxJIDN/AcbUJcpnbcqiL7ifmtx/M7m8UXEMfdOQ0+wdpporwsBLaCpJLMG3oEBvLvs+G5V65bI1RvXFk4825vdtnl1QkpyL8OI/NmV27GbZQz91RZ6IGy0D1l9U15b5EN6PBPdk8ICkypdR40qpjdhrgo7IOGzTKAl8MhrTSufzh39pDzG9ZnBrcMKWZc0HryphHyqfzX62tVupwTp2ZvB7Rav7ar78bV3PjZqjnxqR5c9i3BgrgLB+CjGCfne1kVhdKmBEtvLERJC7K7usQ7BrFm2BZVMlNHg8BJH1htMFcn7EFF4DPI8fvdWbn985q8cEa9a+DHRPFMHv/Pum8K70r+CyWqxT1LCQAA';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var allTransitions = '_1dlf4348';
export var appScreen = _7a468({defaultClassName:'dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 dhthxqc',variantClassNames:{transitionState:{'enter-active':'_1dlf4349','enter-done':'_1dlf434a','exit-active':'_1dlf434b','exit-done':'_1dlf434c'}},defaultVariants:{},compoundVariants:[]});
export var background = '_1dlf4347';
export var dim = '_1dlf434i dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 _1dlf4346 _1dlf4348';
export var edge = _7a468({defaultClassName:'_1dlf434n dhthxq4 dhthxq2 dhthxq3 dhthxq1',variantClassNames:{hasAppBar:{true:'_1dlf434o'}},defaultVariants:{},compoundVariants:[]});
export var enterActive = '_1dlf4349';
export var enterDone = '_1dlf434a';
export var exitActive = '_1dlf434b';
export var exitDone = '_1dlf434c';
export var paper = _7a468({defaultClassName:'_1dlf434j dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 _1dlf4347 _1dlf4348',variantClassNames:{hasAppBar:{true:'_1dlf434k dhthxqg'},modalPresentationStyle:{fullScreen:'_1dlf434l'},activityEnterStyle:{slideInLeft:'_1dlf434m'}},defaultVariants:{},compoundVariants:[]});
export var vars = {transitionDuration:'var(--_1dlf4340)',zIndexes:{dim:'var(--_1dlf4341)',paper:'var(--_1dlf4342)',edge:'var(--_1dlf4343)',appBar:'var(--_1dlf4344)'},appBar:{center:{mainWidth:'var(--_1dlf4345)'}}};