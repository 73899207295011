import "@daangn/sprout-css/radio-group/index.css";

import type {
  RadioGroupProps,
  RadioProps,
} from "@daangn/sprout-hooks-radio-group";
import {
  useRadioGroup,
  useRadioGroupState,
} from "@daangn/sprout-hooks-radio-group";
import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import React from "react";

import { RadioContext } from "./context";

export interface SeedRadioGroupProps extends RadioGroupProps, BaseStyleProps {
  /**
   * @default large
   */
  size: "large" | "medium" | "small";

  /**
   * The Radio(s) contained within the RadioGroup.
   */
  children: React.ReactElement<RadioProps> | React.ReactElement<RadioProps>[];
}

const RadioGroup = (props: SeedRadioGroupProps, ref: Ref<HTMLDivElement>) => {
  const { children, size = "large", ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);
  const state = useRadioGroupState(otherProps);
  const api = useRadioGroup(state);

  return (
    <div
      ref={ref}
      {...styleProps}
      {...api.rootProps}
      className={composeClassName("seed-radio-group", styleProps.className)}
      data-size={size}
    >
      <div data-part="fieldgroup">
        <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _RadioGroup = React.forwardRef(RadioGroup);
export { _RadioGroup as RadioGroup };
