import { ariaAttr, dataAttr } from "@zag-js/dom-query";
import type { NormalizeProps, PropTypes } from "@zag-js/types";
import { visuallyHiddenStyle } from "@zag-js/visually-hidden";

import { parts } from "./radio-group.anatomy";
import { dom } from "./radio-group.dom";
import type { RadioApi, RadioProps, Send, State } from "./radio-group.types";

export function connect<T extends PropTypes>(
  props: RadioProps,
  state: State,
  send: Send,
  normalize: NormalizeProps<T>,
): RadioApi<T> {
  const isGroupDisabled = state.context.disabled;
  const isGroupReadOnly = state.context.readOnly;

  const isReadOnly = props.readOnly || isGroupReadOnly;
  const isInvalid = props.invalid;
  const isDisabled = props.disabled || isGroupDisabled;
  const isSelected = state.context.value === props.value;
  const isFocused = state.context.focusedId === props.value;
  const isHovered = state.context.hoveredId === props.value;
  const isActive = state.context.activeId === props.value;
  const isInteractive = !(isReadOnly || isDisabled);

  const isRequired = props.required;
  const trulyDisabled = isDisabled && !props.focusable;

  const dataSet = {
    "data-focus": dataAttr(isFocused),
    "data-disabled": dataAttr(isDisabled),
    "data-selected": dataAttr(isSelected),
    "data-hover": dataAttr(isHovered),
    "data-pressed": dataAttr(isActive),
    "data-invalid": dataAttr(isInvalid),
    "data-readonly": dataAttr(isReadOnly),
  };

  return {
    isSelected,
    radioProps: normalize.label({
      ...parts.radio.attrs,
      id: dom.getRadioId(state.context, props.value),
      htmlFor: dom.getRadioInputId(state.context, props.value),
      ...dataSet,

      onPointerMove() {
        if (!isInteractive) return;
        send({ type: "SET_HOVERED", value: props.value, hovered: true });
      },
      onPointerLeave() {
        if (!isInteractive) return;
        send({ type: "SET_HOVERED", value: null });
      },
      onPointerDown(event) {
        if (!isInteractive) return;
        // On pointerdown, the input blurs and returns focus to the `body`,
        // we need to prevent this.
        if (isFocused && event.pointerType === "mouse") {
          event.preventDefault();
        }
        send({ type: "SET_ACTIVE", value: props.value, active: true });
      },
      onPointerUp() {
        if (!isInteractive) return;
        send({ type: "SET_ACTIVE", value: null });
      },
    }),

    radioLabelProps: normalize.element({
      ...parts.radioLabel.attrs,
      id: dom.getRadioLabelId(state.context, props.value),
      ...dataSet,
    }),

    radioControlProps: normalize.element({
      ...parts.radioControl.attrs,
      id: dom.getRadioControlId(state.context, props.value),
      "aria-hidden": true,
      ...dataSet,
    }),

    radioInputProps: normalize.input({
      ...parts.radioInput.attrs,
      "data-ownedby": dom.getRootId(state.context),
      id: dom.getRadioInputId(state.context, props.value),

      type: "radio",
      name: state.context.name || state.context.id,
      form: state.context.form,
      value: props.value,
      onChange(event) {
        if (isReadOnly || isDisabled) {
          return;
        }
        if (event.target.checked) {
          send({ type: "SET_VALUE", value: props.value });
        }
      },
      onBlur() {
        send({ type: "SET_FOCUSED", value: null });
      },
      onFocus() {
        send({ type: "SET_FOCUSED", value: props.value, focused: true });
      },
      onKeyDown(event) {
        if (event.key === " ") {
          send({ type: "SET_ACTIVE", value: props.value, active: true });
        }
      },
      onKeyUp(event) {
        if (event.key === " ") {
          send({ type: "SET_ACTIVE", value: null });
        }
      },
      disabled: trulyDisabled,
      required: isRequired,
      defaultChecked: isSelected,
      "data-disabled": dataAttr(isDisabled),
      "aria-required": ariaAttr(isRequired),
      "aria-invalid": ariaAttr(isInvalid),
      readOnly: isReadOnly,
      "data-readonly": dataAttr(isReadOnly),
      "aria-disabled": ariaAttr(trulyDisabled),
      "aria-checked": ariaAttr(isSelected),
      style: visuallyHiddenStyle,
    }),
  };
}
