import { paramCase } from "change-case";

import type { ColorValue, ContainerStyleProps } from "./types";

type StyleName = string[];
type StyleHandler = (value: any) => string | undefined;
export type StyleHandlers = {
  [key in keyof ContainerStyleProps]: [StyleName, StyleHandler];
};

export function identical<T>(value: T): T {
  return value;
}

function handleColor(value: ColorValue) {
  return `var(--seed-scale-color-${paramCase(
    value,
  )}, var(--seed-static-color-${paramCase(
    value,
  )}, var(--seed-semantic-color-${paramCase(value)})))`;
}

function handleHidden(value: boolean) {
  return value ? "none" : undefined;
}

function handleFlex(value: boolean | number | string) {
  if (typeof value === "boolean") {
    return value ? "1" : undefined;
  }

  return "" + value;
}

export const basePropHandlers: StyleHandlers = {
  margin: [["margin"], identical],
  marginStart: [["marginStart"], identical],
  marginEnd: [["marginEnd"], identical],
  marginTop: [["marginTop"], identical],
  marginBottom: [["marginBottom"], identical],
  marginX: [["marginLeft", "marginRight"], identical],
  marginY: [["marginTop", "marginBottom"], identical],
  width: [["width"], identical],
  height: [["height"], identical],
  minWidth: [["minWidth"], identical],
  minHeight: [["minHeight"], identical],
  maxWidth: [["maxWidth"], identical],
  maxHeight: [["maxHeight"], identical],
  isHidden: [["display"], handleHidden],
  alignSelf: [["alignSelf"], identical],
  justifySelf: [["justifySelf"], identical],
  position: [["position"], identical],
  zIndex: [["zIndex"], identical],
  top: [["top"], identical],
  bottom: [["bottom"], identical],
  start: [["start"], identical],
  end: [["end"], identical],
  left: [["left"], identical],
  right: [["right"], identical],
  order: [["order"], identical],
  flex: [["flex"], handleFlex],
  flexGrow: [["flexGrow"], identical],
  flexShrink: [["flexShrink"], identical],
  flexBasis: [["flexBasis"], identical],
  gridArea: [["gridArea"], identical],
  gridColumn: [["gridColumn"], identical],
  gridColumnEnd: [["gridColumnEnd"], identical],
  gridColumnStart: [["gridColumnStart"], identical],
  gridRow: [["gridRow"], identical],
  gridRowEnd: [["gridRowEnd"], identical],
  gridRowStart: [["gridRowStart"], identical],
};

export const containerPropHandlers: StyleHandlers = {
  ...basePropHandlers,
  backgroundColor: [["backgroundColor"], handleColor],
  borderWidth: [["borderWidth"], identical],
  borderStartWidth: [["borderStartWidth"], identical],
  borderEndWidth: [["borderEndWidth"], identical],
  borderTopWidth: [["borderTopWidth"], identical],
  borderBottomWidth: [["borderBottomWidth"], identical],
  borderXWidth: [["borderLeftWidth", "borderRightWidth"], identical],
  borderYWidth: [["borderTopWidth", "borderBottomWidth"], identical],
  borderColor: [["borderColor"], handleColor],
  borderStartColor: [["borderStartColor"], handleColor],
  borderEndColor: [["borderEndColor"], handleColor],
  borderTopColor: [["borderTopColor"], handleColor],
  borderBottomColor: [["borderBottomColor"], handleColor],
  borderXColor: [["borderLeftColor", "borderRightColor"], handleColor],
  borderYColor: [["borderTopColor", "borderBottomColor"], handleColor],
  borderRadius: [["borderRadius"], identical],
  borderTopStartRadius: [["borderTopStartRadius"], identical],
  borderTopEndRadius: [
    ["borderTopRightRadius", "borderTopLeftRadius"],
    identical,
  ],
  borderBottomStartRadius: [["borderBottomStartRadius"], identical],
  borderBottomEndRadius: [
    ["borderBottomRightRadius", "borderBottomLeftRadius"],
    identical,
  ],
  padding: [["padding"], identical],
  paddingStart: [["paddingLeft", "paddingRight"], identical],
  paddingEnd: [["paddingRight", "paddingLeft"], identical],
  paddingTop: [["paddingTop"], identical],
  paddingBottom: [["paddingBottom"], identical],
  paddingX: [["paddingLeft", "paddingRight"], identical],
  paddingY: [["paddingTop", "paddingBottom"], identical],
  overflow: [["overflow"], identical],
};
