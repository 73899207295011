import "@daangn/sprout-css/chips/index.css";

import type { ButtonElementType } from "@daangn/sprout-hooks-button";
import { useToggleButton } from "@daangn/sprout-hooks-button";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { ElementType, Ref } from "react";
import React from "react";

import type { SeedChipToggleButtonProps } from "./types";

const ChipToggleButton = <T extends ButtonElementType>(
  props: SeedChipToggleButtonProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    elementType: Element = "button" as ElementType,
    children,
    size = "medium",
    prefix,
    suffix,
    ...otherProps
  } = props;
  const { buttonProps, isSelected } = useToggleButton(props);
  const { styleProps } = useStyleProps(otherProps);

  const variant = isSelected ? "emphasized" : "default";

  return (
    <Element
      ref={ref}
      {...styleProps}
      {...buttonProps}
      data-part="root"
      data-size={size}
      data-variant={variant}
      className={composeClassName("seed-chip", styleProps.className)}
    >
      {prefix && <div data-part="prefix">{prefix}</div>}
      <div
        className={classNames.$semantic.typography.bodyM2Regular}
        data-part="label"
      >
        {children}
      </div>
      {suffix && <div data-part="suffix">{suffix}</div>}
    </Element>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _ChipToggleButton = React.forwardRef(ChipToggleButton);
export { _ChipToggleButton as ChipToggleButton };
