import "@daangn/sprout-css/radio-group/index.css";

import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import type { RadioProps } from "@daangn/sprout-hooks-radio-group";
import { useRadio } from "@daangn/sprout-hooks-radio-group";
import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import React from "react";

import { useRadioContext } from "./context";

export interface SeedRadioProps extends RadioProps, BaseStyleProps {
  children?: React.ReactNode;
}

const Radio = (props: SeedRadioProps, ref: Ref<HTMLLabelElement>) => {
  const context = useRadioContext();
  const { children, ...otherProps } = props;
  const { focusVisibleProps } = useFocusVisible();
  const api = useRadio(otherProps, context);
  const { styleProps } = useStyleProps(otherProps);

  return (
    <label
      ref={ref}
      {...styleProps}
      {...focusVisibleProps}
      {...api.radioProps}
      className={composeClassName("seed-radio", styleProps.className)}
    >
      <input {...api.radioInputProps} />
      <div {...api.radioControlProps} />
      <span {...api.radioLabelProps} className="seed-typography-subtitle1-bold">
        {children}
      </span>
    </label>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Radio = React.forwardRef(Radio);
export { _Radio as Radio };
